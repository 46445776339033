import { roles } from "../config/roles";
export const menuItems = [
  {
    id: 1,
    label: "menuitems.dashboard.text",
    icon: "ri-dashboard-line",
    link: "/dashboard_home",
    permissions: roles.home,
  },
  {
    id: 2,
    label: "menuitems.users.text",
    icon: "ri-group-line",
    subItems: [
      {
        id: 201,
        label: "Users",
        link: "/users/all",
        permissions: roles.UsersAll,
      },
      {
        id: 202,
        label: "menuitems.users.list.roles",
        link: "/users/roles",
        permissions: roles.UserRoles,
      },
      {
        id: 203,
        label: "menuitems.users.list.permissions",
        link: "/users/permissions",
        permissions: roles.UsersPermissions,
      },
      {
        id: 204,
        label: "OTP",
        link: "/users/otp",
        permissions: roles.UsersPermissions,
      },
    ],
  },
  {
    id: 145,
    label: "Driver Ad",
    icon: "ri-car-line",
    link: "/vehicles-ad",
    permissions: roles.DriversAll,
  },
  {
    id: 146,
    label: "Vehicle Company",
    icon: "ri-truck-fill",
    link: "/vehicles-company",
    permissions: roles.VehicleCompany,
  },
  {
    id: 3,
    label: "menuitems.driver.text",
    icon: "ri-car-line",
    link: "/drivers/all",
    permissions: roles.DriversAll,
  },
  {
    id: 4,
    icon: "ri-settings-line",
    label: "Settings",
    subItems: [
      {
        id: 401,
        label: "Plans",
        link: "/plans/all",
        permissions: roles.PlansAll,
      },
      {
        id: 402,
        label: "Banks",
        link: "/banks/all",
        permissions: roles.BanksAll,
      },
      {
        id: 403,
        label: "menuitems.vehicle.list.manufactures",
        link: "/vehicle/manufactures/all",
        permissions: roles.VehicleManufacturesAll,
      },
      {
        id: 404,
        label: "menuitems.vehicle.list.models",
        link: "/vehicle/models/all",
        permissions: roles.VehicleModelsAll,
      },
      {
        id: 405,
        label: "Locations",
        link: "/locations",
        permissions: roles.Location,
      },
    ],
  },
  {
    id: 5,
    icon: "ri-vip-diamond-line",
    label: "Campaigns",
    permissions: roles.CampaignsAll,
    subItems: [
      {
        id: 18,
        label: "Campaigns",
        link: "/campaigns/all",
        permissions: roles.CampaignsAll,
      },
      {
        id: 17,
        label: "Bank Payment",
        link: "/campaigns/payment/all",
        permissions: roles.CampaignsAll,
      },
    ],
  },
  {
    id: 5,
    icon: "ri-device-fill",
    label: "Display Devices",
    permissions: roles.DisplayDevicesAll,
    subItems: [
      {
        id: 18,
        label: "Display Devices",
        link: "/displaydevices/all",
        permissions: roles.DisplayDevicesAll,
      },

    ],
  },
  {
    id: 5,
    icon: "ri-advertisement-fill",
    label: "Display Ads",
    permissions: roles.DisplayAdsAll,
    subItems: [
      {
        id: 18,
        label: "Display Ads",
        link: "/displayads/all",
        permissions: roles.DisplayAdsAll,
      },

    ],
  },
  {
    id: 18,
    icon: "ri-earth-fill",
    label: "Region",
    link: "/region",
    permissions: roles.UnpaidCampaign,
  },

  {
    id: 20,
    icon: "ri-earth-fill",
    label: "City",
    link: "/city",
    permissions: roles.UnpaidCampaign,
  },

  {
    id: 12,
    icon: "ri-error-warning-line",
    label: "Unpaid Campaigns",
    link: "/unpaid-campaigns",
    permissions: roles.UnpaidCampaign,
  },

  {
    id: 6,
    icon: "ri-error-warning-line",
    label: "Partial Completed",
    link: "/campaigns/partial-complete",
    permissions: roles.CampaignsPartialComplete,
  },
  {
    id: 7,
    icon: "ri-notification-line",
    label: "Notification",
    link: "/fcm",
    permissions: roles.FcmIndex,
  },
  {
    id: 8,
    icon: "ri-community-line",
    label: "Workstations",
    link: "/workstations",
    permissions: roles.Workstations,
  },
  {
    id: 9,
    icon: "ri-money-dollar-circle-fill",
    label: "Transactions",
    link: "/txn/all",
    permissions: roles.Transactions,
  },
  {
    id: 11,
    icon: "ri-image-line",
    label: "Banner",
    link: "/banner",
    permissions: roles.Banner,
  },
  {
    id: 19,
    icon: "ri-customer-service-2-line",
    label: "Tickets",
    link: "/tickets",
    permissions: roles.Tickets,
  },
  // Designers specific
  {
    id: 10,
    icon: "ri-vip-diamond-line",
    label: "Campaigns",
    link: "/designers/campaigns/all",
    permissions: roles.allCampaignDesigners,
  },

  // Partner specific
  {
    id: 11,
    icon: "ri-vip-diamond-line",
    label: "Jobs",
    link: "/partner/jobs",
    permissions: roles.ScheduledJob,
  },
  {
    id: 12,
    icon: "ri-search-eye-fill",
    label: "Inspect",
    link: "/partner/inspect",
    permissions: roles.InspectVehicle,
  },
  {
    id: 13,
    icon: "ri-time-line",
    label: "Work Time",
    link: "/partner/work-time",
    permissions: roles.AdjustWorkingTime,
  },
  {
    id: 14,
    icon: "ri-building-line",
    label: "Company Banner",
    link: "/company_banner",
    permissions: roles.Banner,
  },
  {
    id: 15,
    label: "menuitems.inspections.text",
    icon: "ri-car-line",
    link: "/inspections/all",
    permissions: roles.DriversAll,
  },
  {
    id: 105,
    label: "Terminals",
    icon: "ri-broadcast-fill",
    link: "/terminals/all",
    permissions: roles.DriversAll,
  },
  {
    id: 16,
    label: "Advertiser",
    icon: "ri-advertisement-line",
    link: "/advertisers/all",
    permissions: roles.AdvertiserAll,
  },
  {
    id: 17,
    label: "Troubleshoot",
    icon: "ri-information-line",
    link: "/troubleshoot",
    permissions: roles.Troubleshoot,
  },

  // {
  //     id: 3,
  //     label: 'menuitems.calendar.text',
  //     icon: 'ri-calendar-2-line',
  //     link: '/calendar'
  // },
  // {
  //     id: 4,
  //     label: 'menuitems.chat.text',
  //     icon: 'ri-chat-1-line',
  //     link: '/chat'
  // },
  // {
  //     id: 5,
  //     label: 'menuitems.ecommerce.text',
  //     icon: 'ri-store-2-line',
  //     subItems: [
  //         {
  //             id: 6,
  //             label: 'menuitems.ecommerce.list.products',
  //             link: '/ecommerce/products'
  //         },
  //         {
  //             id: 7,
  //             label: 'menuitems.ecommerce.list.productdetail',
  //             link: '/ecommerce/product-detail'
  //         },
  //         {
  //             id: 8,
  //             label: 'menuitems.ecommerce.list.orders',
  //             link: '/ecommerce/orders'
  //         },
  //         {
  //             id: 9,
  //             label: 'menuitems.ecommerce.list.customers',
  //             link: '/ecommerce/customers'
  //         },
  //         {
  //             id: 10,
  //             label: 'menuitems.ecommerce.list.cart',
  //             link: '/ecommerce/cart'
  //         },
  //         {
  //             id: 11,
  //             label: 'menuitems.ecommerce.list.checkout',
  //             link: '/ecommerce/checkout'
  //         },
  //         {
  //             id: 12,
  //             label: 'menuitems.ecommerce.list.shops',
  //             link: '/ecommerce/shops'
  //         },
  //         {
  //             id: 13,
  //             label: 'menuitems.ecommerce.list.addproduct',
  //             link: '/ecommerce/add-product'
  //         }
  //     ]
  // },
  // {
  //     id: 14,
  //     label: 'menuitems.email.text',
  //     icon: 'ri-mail-send-line',
  //     subItems: [
  //         {
  //             id: 15,
  //             label: 'menuitems.email.list.inbox',
  //             link: '/email/inbox'
  //         },
  //         {
  //             id: 16,
  //             label: 'menuitems.email.list.reademail',
  //             link: '/email/read'
  //         }
  //     ]
  // },
  // {
  //     id: 17,
  //     label: 'menuitems.kanban.text',
  //     icon: 'ri-artboard-2-line',
  //     link: '/apps/kanban-board'
  // },
  // {
  //     id: 18,
  //     isLayout: true
  // },
  // {
  //     id: 19,
  //     label: 'menuitems.pages.text',
  //     isTitle: true
  // },
  // {
  //     id: 20,
  //     label: 'menuitems.authentication.text',
  //     icon: 'ri-account-circle-line',
  //     subItems: [
  //         {
  //             id: 21,
  //             label: 'menuitems.authentication.list.login',
  //             link: '/auth/login-1'
  //         },
  //         {
  //             id: 22,
  //             label: 'menuitems.authentication.list.register',
  //             link: '/auth/register-1'
  //         },
  //         {
  //             id: 23,
  //             label: 'menuitems.authentication.list.recoverpwd',
  //             link: '/auth/recoverpwd-1'
  //         },
  //         {
  //             id: 24,
  //             label: 'menuitems.authentication.list.lockscreen',
  //             link: '/auth/lock-screen-1'
  //         }
  //     ]
  // },
  // {
  //     id: 25,
  //     label: 'menuitems.utility.text',
  //     icon: 'ri-profile-line',
  //     subItems: [
  //         {
  //             id: 26,
  //             label: 'menuitems.utility.list.starter',
  //             link: '/pages/starter'
  //         },
  //         {
  //             id: 27,
  //             label: 'menuitems.utility.list.maintenance',
  //             link: '/pages/maintenance'
  //         },
  //         {
  //             id: 28,
  //             label: 'menuitems.utility.list.comingsoon',
  //             link: '/pages/coming-soon'
  //         },
  //         {
  //             id: 29,
  //             label: 'menuitems.utility.list.timeline',
  //             link: '/pages/timeline'
  //         },
  //         {
  //             id: 30,
  //             label: 'menuitems.utility.list.faqs',
  //             link: '/pages/faqs'
  //         },
  //         {
  //             id: 31,
  //             label: 'menuitems.utility.list.pricing',
  //             link: '/pages/pricing'
  //         },
  //         {
  //             id: 32,
  //             label: 'menuitems.utility.list.error404',
  //             link: '/pages/error-404'
  //         },
  //         {
  //             id: 33,
  //             label: 'menuitems.utility.list.error500',
  //             link: '/pages/error-500'
  //         },
  //     ]
  // },
  // {
  //     id: 36,
  //     label: 'menuitems.components.text',
  //     isTitle: true
  // },
  // {
  //     id: 37,
  //     label: 'menuitems.uielements.text',
  //     icon: 'ri-pencil-ruler-2-line',
  //     subItems: [
  //         {
  //             id: 38,
  //             label: 'menuitems.uielements.list.alerts',
  //             link: '/ui/alerts'
  //         },
  //         {
  //             id: 39,
  //             label: 'menuitems.uielements.list.buttons',
  //             link: '/ui/buttons'
  //         },
  //         {
  //             id: 40,
  //             label: 'menuitems.uielements.list.cards',
  //             link: '/ui/cards'
  //         },
  //         {
  //             id: 41,
  //             label: 'menuitems.uielements.list.carousel',
  //             link: '/ui/carousel'
  //         },
  //         {
  //             id: 42,
  //             label: 'menuitems.uielements.list.dropdowns',
  //             link: '/ui/dropdowns'
  //         },
  //         {
  //             id: 43,
  //             label: 'menuitems.uielements.list.grid',
  //             link: '/ui/grid'
  //         },
  //         {
  //             id: 44,
  //             label: 'menuitems.uielements.list.images',
  //             link: '/ui/images'
  //         },
  //         {
  //             id: 45,
  //             label: 'menuitems.uielements.list.lightbox',
  //             link: '/ui/lightbox'
  //         }, {
  //             id: 46,
  //             label: 'menuitems.uielements.list.modals',
  //             link: '/ui/modals'
  //         }, {
  //             id: 47,
  //             label: 'menuitems.uielements.list.rangeslider',
  //             link: '/ui/rangeslider'
  //         }, {
  //             id: 48,
  //             label: 'menuitems.uielements.list.sessiontimeout',
  //             link: '/ui/session-timeout'
  //         },
  //         {
  //             id: 49,
  //             label: 'menuitems.uielements.list.progressbar',
  //             link: '/ui/progressbar'
  //         }, {
  //             id: 50,
  //             label: 'menuitems.uielements.list.sweetalert',
  //             link: '/ui/sweet-alert'
  //         }, {
  //             id: 51,
  //             label: 'menuitems.uielements.list.tabs',
  //             link: '/ui/tabs-accordion'
  //         }, {
  //             id: 52,
  //             label: 'menuitems.uielements.list.typography',
  //             link: '/ui/typography'
  //         }, {
  //             id: 53,
  //             label: 'menuitems.uielements.list.video',
  //             link: '/ui/video'
  //         }, {
  //             id: 54,
  //             label: 'menuitems.uielements.list.general',
  //             link: '/ui/general'
  //         }, {
  //             id: 55,
  //             label: 'menuitems.uielements.list.rating',
  //             link: '/ui/rating'
  //         }, {
  //             id: 56,
  //             label: 'menuitems.uielements.list.notifications',
  //             link: '/ui/notification'
  //         }
  //     ]
  // },
  // {
  //     id: 57,
  //     label: 'menuitems.forms.text',
  //     icon: 'ri-eraser-fill',
  //     badge: {
  //         variant: 'danger',
  //         text: 'menuitems.forms.badge'
  //     },
  //     subItems: [
  //         {
  //             id: 58,
  //             label: 'menuitems.forms.list.elements',
  //             link: '/form/elements'
  //         },
  //         {
  //             id: 59,
  //             label: 'menuitems.forms.list.validation',
  //             link: '/form/validation'
  //         },
  //         {
  //             id: 60,
  //             label: 'menuitems.forms.list.advanced',
  //             link: '/form/advanced'
  //         },
  //         {
  //             id: 61,
  //             label: 'menuitems.forms.list.editor',
  //             link: '/form/editor'
  //         },
  //         {
  //             id: 62,
  //             label: 'menuitems.forms.list.fileupload',
  //             link: '/form/uploads'
  //         },
  //         {
  //             id: 63,
  //             label: 'menuitems.forms.list.wizard',
  //             link: '/form/wizard'
  //         },
  //         {
  //             id: 64,
  //             label: 'menuitems.forms.list.mask',
  //             link: '/form/mask'
  //         }
  //     ]
  // },
  // {
  //     id: 65,
  //     label: 'menuitems.tables.text',
  //     icon: 'ri-table-2',
  //     subItems: [
  //         {
  //             id: 66,
  //             label: 'menuitems.tables.list.basic',
  //             link: '/tables/basic'
  //         },
  //         {
  //             id: 67,
  //             label: 'menuitems.tables.list.advanced',
  //             link: '/tables/advanced'
  //         },
  //         // {
  //         //     id: 68,
  //         //     label: 'Responsive Table',
  //         //     link: ''
  //         // },
  //     ]
  // },
  // {
  //     id: 69,
  //     label: 'menuitems.charts.text',
  //     icon: 'ri-bar-chart-line',
  //     subItems: [
  //         {
  //             id: 70,
  //             label: 'menuitems.charts.list.apex',
  //             link: '/charts/apex'
  //         },
  //         {
  //             id: 71,
  //             label: "menuitems.charts.list.chartjs",
  //             link: '/charts/chartjs'
  //         },
  //         {
  //             id: 72,
  //             label: "menuitems.charts.list.chartist",
  //             link: '/charts/chartist'
  //         },
  //         {
  //             id: 73,
  //             label: "menuitems.charts.list.echart",
  //             link: '/charts/echart'
  //         }
  //     ]
  // },
  // {
  //     id: 74,
  //     label: "menuitems.icons.text",
  //     icon: 'ri-brush-line',
  //     subItems: [
  //         {
  //             id: 75,
  //             label: 'menuitems.icons.list.remix',
  //             link: '/icons/remix'
  //         },
  //         {
  //             id: 76,
  //             label: "menuitems.icons.list.materialdesign",
  //             link: '/icons/material-design'
  //         },
  //         {
  //             id: 77,
  //             label: "menuitems.icons.list.dripicons",
  //             link: '/icons/dripicons'
  //         },
  //         {
  //             id: 78,
  //             label: "menuitems.icons.list.fontawesome",
  //             link: '/icons/font-awesome'
  //         }
  //     ]
  // },
  // {
  //     id: 79,
  //     label: "menuitems.maps.text",
  //     icon: 'ri-map-pin-line',
  //     subItems: [
  //         {
  //             id: 80,
  //             label: "menuitems.maps.list.googlemap",
  //             link: '/maps/google'
  //         },
  //         {
  //             id: 81,
  //             label: 'menuitems.maps.list.leaflet',
  //             link: '/maps/leaflet'
  //         }
  //     ]
  // }
];
